






























// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ToggleUser',
  components: {
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isMember(): boolean {
      return this.user.memberStatus === 'member' || this.user.memberStatus === 'active';
    },
    request(): FormWrapperRequest {
      return {
        config: {
          data: { activate: this.user.memberStatus === 'de-active' },
          method: 'put',
          url: `/org/${this.$route.params.orgId}/user/${this.$route.params.id}/status`,
        },
      };
    },
  },
  data() {
    return {
    };
  },
  methods: {
    async onSubmit() {
      this.error = '';

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.notify('User updated successfully');
      (this.$refs.modal as any).close();
      this.$emit('submit');
    },
    openModal() {
      (this.$refs.modal as any).open();
    },
  },
});
