




















import Vue from 'vue';
import { OrgModel } from '@/types/org';
import { UserDetails } from '@/types/profile';
import ApiService from '@/services/api.service';
import Page from '@/layouts/Page.vue';
import AppCard from '@/components/card/AppCard.vue';
import ToggleUser from '@/views/admin/org/users/ToggleUser.vue';
import notify from '@/util/notify';
import ViewMember from '@/views/admin/org/users/ViewMember.vue';
import ViewInvitee from '@/views/admin/org/users/ViewInvitee.vue';
import DeleteModal from '@/components/DeleteModal.vue';

function getDefaultModel() {
  return {
    id: '',
    title: '',
    displayName: '',
    ourPeople: false,
    memberStatus: '',
    invitationActions: [],
    invitationId: '',
    bio: '',
  };
}

export default Vue.extend({
  name: 'ViewUser',
  components: {
    DeleteModal,
    ViewInvitee,
    ViewMember,
    AppCard,
    Page,
    ToggleUser,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    profile(): UserDetails {
      return this.$store.getters['profileModule/user'];
    },
    isMember(): boolean {
      return ['member', 'active', 'de-active'].indexOf(this.user.memberStatus) > -1;
    },
    isOwn(): boolean {
      return this.user.id === this.profile.id;
    },
    userTitle(): string {
      return this.user.displayName + (this.user.memberStatus === 'de-active' ? ' is DEACTIVATED' : '');
    },
    breadcrumbs(): any[] {
      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/users`,
        },
        {
          label: this.user.displayName,
        },
      ];
    },
  },
  data() {
    return {
      user: getDefaultModel(),
    };
  },
  methods: {
    canTakeAction(availableAction: string): boolean {
      if (availableAction) {
        return availableAction.length > 0;
      }
      return false;
    },
    async editInvitation(newStatus: string, invitationId: string) {
      if (newStatus && invitationId) {
        try {
          await ApiService.put(`/org/${this.org.id}/user/invite/${invitationId}/${newStatus}`);
          await this.loadUser();
          notify.success(`Invitation status updated to: ${newStatus}`);
        } catch (e: any) {
          notify.danger(`Invitation updated failed ${e.message}`);
        }
      }
    },
    async loadUser() {
      try {
        this.user = await ApiService.get(`/org/${this.org.id}/user/${this.$route.params.id}`);
      } catch {
        this.user = getDefaultModel();
      }
    },
  },
  async created() {
    await this.loadUser();
  },
});
