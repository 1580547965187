




















import Vue from 'vue';
import { OrgModel } from '@/types/org';
import { UserDetails } from '@/types/profile';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import Btn from '@/components/buttons/Btn.vue';
import { capitalizeFirstWord } from '@/util/strings';

export default Vue.extend({
  name: 'ViewInvitee',
  components: {
    Btn,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    profile(): UserDetails {
      return this.$store.getters['profileModule/user'];
    },
    breadcrumbs(): any[] {
      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}`,
        },
        {
          label: 'Manage',
          url: `/org/${this.org.id}`,
        },
        {
          label: 'Users',
          url: `/org/${this.org.id}/users`,
        },
        {
          label: this.user.displayName,
        },
      ];
    },
  },
  methods: {
    limitReissue(action: string): boolean {
      if (action === 'reissue') {
        const lastReissue = new Date(this.user.updatedAt);
        const hourAgo = new Date();
        hourAgo.setHours(hourAgo.getHours() - 1);
        return lastReissue > hourAgo;
      }
      return false;
    },
    actionLabel(action: string): string {
      if (this.limitReissue(action)) {
        return 'Re-issuing limited to once an hour';
      }
      return `${capitalizeFirstWord(action)} this invitation`;
    },
    canTakeAction(availableAction: string): boolean {
      if (availableAction) {
        return availableAction.length > 0;
      }
      return false;
    },
    async editInvitation(newStatus: string, invitationId: string) {
      if (newStatus && invitationId) {
        try {
          await ApiService.put(`/org/${this.org.id}/user/invite/${invitationId}/${newStatus}`);
          this.$emit('success');
          notify.success(`Invitation status updated to: ${newStatus}`);
        } catch (e: any) {
          notify.danger(`Invitation updated failed ${e.message}`);
        }
      }
    },
  },
});
