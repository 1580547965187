<template>
  <q-select
    :value="value"
    @input="$emit('input', $event)"
    :disable="disable"
    dense
    outlined
    emit-value
    map-options
    :options="options"
    label="Security Level"
  >
    <template #prepend>
      <q-badge :color="color"/>
    </template>
  </q-select>
</template>

<script>
import { securityLevels } from '@/util/securityLevels';

export default {
  name: 'FieldSecurityLevel',
  props: {
    value: Number,
    disable: Boolean,
  },
  computed: {
    color() {
      return securityLevels[this.value].color;
    },
  },
  data() {
    const userLevel = this.$store.getters['orgModule/userSecurityLevel'];
    return {
      options: Object.values(securityLevels).filter((l) => l.value <= userLevel),
    };
  },
};
</script>
