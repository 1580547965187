











































































import Vue from 'vue';
import { OrgModel } from '@/types/org';
import { UserDetails } from '@/types/profile';
import SocialDetails from '@/components/social/SocialDetails.vue';
import { SocialDetailsMap } from '@/types/social';
import AppAvatar from '@/components/avatar/Avatar.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import EditUser from '@/views/admin/org/users/EditUser.vue';
import { availableRoles } from '@/components/admin/org/roleInformation';
import { Roles, SecurityLevel } from '@/types';
import FieldSecurityLevel from '@/components/fields/FieldSecurityLevel.vue';
import Btn from '@/components/buttons/Btn.vue';

function getDefaultSocial(): SocialDetailsMap {
  return {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    website: '',
  };
}

export default Vue.extend({
  name: 'ViewMember',
  components: {
    Btn,
    FieldSecurityLevel,
    AppAvatar,
    SocialDetails,
    EditUser,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    profile(): UserDetails {
      return this.$store.getters['profileModule/user'];
    },
    isEditable(): boolean {
      return !this.isActiveWithinOrg || this.$route.params.id === this.profile.id
        || !this.user.isActive;
    },
    userTitle(): string {
      return this.user.displayName + (this.user.memberStatus === 'de-active' ? ' is DEACTIVATED' : '');
    },
    isActiveWithinOrg(): boolean {
      return this.user.memberStatus !== 'de-active';
    },
    ourPeopleTip(): string {
      return this.user.displayName + (this.user.ourPeople ? ' is a member of our people' : ' is NOT one of our people');
    },
  },
  data() {
    return {
      socialDetails: getDefaultSocial(),
      availableRoles: availableRoles.slice(1),
    };
  },
  methods: {
    async goToRole(roleId: string) {
      await this.$router.push(`/org/${this.$route.params.orgId}/role/${roleId}`);
    },
    async addRole(role: Roles) {
      try {
        if (role > -1) {
          const payload = { users: [this.$route.params.id], role };
          await ApiService.post(`/org/${this.org.id}/role/assign`, payload);
          notify.success('Role successfully added to the user');
          this.$emit('changed');
        }
      } catch {
        notify.danger('Failed to change roles for this user');
      }
    },
    async addSecurity(security: SecurityLevel) {
      try {
        if (security > -1) {
          const payload = { users: [this.$route.params.id], securityLevel: security };
          await ApiService.post(`/org/${this.org.id}/user/security`, payload);
          this.$emit('changed');
          notify.success('Security Level successfully added to the user');
        }
      } catch {
        notify.danger('Failed to change the security level for this user');
      }
    },
    async reactivateUser() {
      try {
        await ApiService.put(`/org/${this.org.id}/user/${this.$route.params.id}/reactivate`);
        this.$emit('changed');
        notify.success('The user has been reactivated successfully');
      } catch {
        notify.danger('Failed to reactivate this user');
      }
    },
  },
  async created() {
    this.socialDetails.facebook = this.user.facebook;
    this.socialDetails.instagram = this.user.instagram;
    this.socialDetails.linkedin = this.user.linkedin;
    this.socialDetails.twitter = this.user.twitter;
    this.socialDetails.website = this.user.website;
  },
});
