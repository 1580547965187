































import Vue from 'vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import { OrgModel } from '@/types/org';
import { logActionData, LogActionData } from '@/util/logAction';
import DescriptionCol from '@/components/list/DescriptionCol.vue';

export default Vue.extend({
  name: 'ListAudits',
  components: {
    ListWithFilter,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `/org/${this.details.id}/audit/info`;
    },
    deleteUrl(): string {
      return `/org/${this.details.id}/audit/remove`;
    },
    details(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'projectLabel',
          label: 'Project',
          field: 'projectLabel',
          align: 'left',
          sortable: true,
        },
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: true,
        },
        {
          name: 'type',
          label: 'Type',
          field: 'type',
          align: 'left',
          sortable: true,
        },
        {
          name: 'action',
          label: 'Action',
          field: 'action',
          align: 'center',
          sortable: true,
        },
        {
          name: 'deletable',
          label: 'Has Data',
          field: 'deletable',
          align: 'left',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    // todo come back to do this once a set of actions has been decided upon
    actionValue(action: string): LogActionData {
      return logActionData[action] || logActionData.default;
    },
  },
});
