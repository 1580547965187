export type LogActionData = { label: string, color: string };

export const logActionData: Record<string, LogActionData> = Object.freeze({
  c: {
    label: 'Created',
    color: 'green',
  },
  u: {
    label: 'Edited',
    color: 'blue',
  },
  d: {
    label: 'Deleted',
    color: 'orange',
  },
  x: {
    label: 'Data Cleared',
    color: 'cyan',
  },
  default: {
    label: 'None',
    color: 'black',
  },
});
