


















import Vue from 'vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';

export default Vue.extend({
  name: 'ListLogs',
  components: {
    ListWithFilter,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return '/login/info';
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          field: 'createdAt',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
});
